import axios from 'axios';
import { CardList } from 'components/CardList';
import { Loading } from 'components/Loading';
import { Title } from 'components/Title';
import Main from 'layout/Main';
import { isEmpty } from 'lodash';
import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import useSWR, { SWRConfig } from 'swr';
import type { CardType } from 'types';

const fetcher = (url: string) => axios.get(url).then((res) => res.data);
const SearchTitle = styled(Title)`
  span {
    color: ${({ theme }) => theme.colors.cherry};
  }
`;

const Text = styled.div`
  font-size: 1rem;
  margin: 0;
  padding: 0;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const HomeContent = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchData, setSearchData] = useState<CardType[]>([]);
  const { data: latest } = useSWR<CardType[]>('/api/latest?limit=4', fetcher);

  const requestCards = async (word: string) => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/api/search?q=${word}`);
      setSearchData(data);
    } catch (err) {
      toast.error('Ops!! No candy for you.');
    }
    setLoading(false);
  };

  useEffect(() => {
    const { q } = router.query;
    const word = Array.isArray(q) ? q[0] : q;
    setSearchWord(word || '');
  }, [router.query]);

  useEffect(() => {
    if (!searchWord || isEmpty(searchWord)) {
      setSearchData([]);
      return;
    }

    requestCards(searchWord);
  }, [searchWord]);

  return (
    <>
      <Head>
        <title>Where DaFuk??</title>
      </Head>
      <Main>
        <>
          {loading && <Loading />}
          {latest && !searchWord && (
            <>
              <Wrapper>
                <Image
                  src="/images/brand.png"
                  width={130}
                  height={128}
                  layout="fixed"
                  alt="placeholder"
                />
                <div>
                  <Title>Where Da FVK ????</Title>
                  <Text>Bookmarks are PREHISTORIC!!!!!!</Text>
                </div>
              </Wrapper>
              <SearchTitle>Latest awesome links</SearchTitle>
              <CardList data={latest} />
            </>
          )}
          {searchData && searchWord && !loading && (
            <>
              <SearchTitle>
                Searched for: <span>{searchWord}</span>
              </SearchTitle>
              <CardList data={searchData} />
            </>
          )}
        </>
      </Main>
    </>
  );
};

const Home: NextPage = () => {
  return (
    <SWRConfig>
      <HomeContent />
    </SWRConfig>
  );
};

export default Home;
